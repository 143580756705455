import React from "react";
import StorePros from "./StorePros";
import { Button } from "./CustomComponents";

const ShopLinkPharmacy = () => {
  return (
    <div className="m-0 md:m-12">
      <div className="flex flex-col md:flex-row md:justify-between items-center font-opensans">
        <h1 className="text-xl md:text-3xl font-light text-stone-800">Shop Link Pharmacy your way</h1>

        <div className="flex flex-1 items-center">
          <div className="min-w-max ml-5 mr-2">
            <Button href={"/upload-prescription"} width={"100%"}>
              Upload myPrescriptions
            </Button>
          </div>
          <hr className="hidden md:block border-t border-stone-800 w-full" />
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 flex-wrap md:flex-nowrap justify-around items-center pt-8">
        <StorePros />
      </div>
    </div>
  );
};
export default ShopLinkPharmacy;
