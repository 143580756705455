import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import Typewriter from "typewriter-effect";
import "react-multi-carousel/lib/styles.css";
import Layout from "../components/Layout/Layout";
import ServiceCards from "../components/ServiceCards";
import ShopLinkPharmacy from "../components/ShopLinkPharmacy";
import { carouselResponsiveness, categoryCards, insuranceCards, meta } from "../globals";
import googlePlayImage from "../assets/images/google-play.png";
import iosImage from "../assets/images/app-store.png";
import linkpharmacyLogo from "../assets/images/link_footer_logo.png";
import linkMedClinicLogo from "../assets/images/mediclinic-logo.png";
import { Button } from "../components/CustomComponents";

const Home = () => {
  return (
    <Layout
      meta={{
        title: meta.homeTitle,
        keywords: meta.homeKeywords,
        description: meta.homeDescription,
      }}
      header
      footer
    >
      <div className="container-lg container-md container-sm container mt-2 z-10">
        <ServiceCards bgImg="md:bg-imgs-mediclinic" mobileBgImg="bg-imgs-mediclinic-g">
          <img src={linkMedClinicLogo} className="w-60 md:w-80 object-contain flex" alt="Link Mediclinic Logo" />
          <div className="text-stone-700 w-full flex flex-col md:space-y-1 mb-1 md:mb-3">
            <span className="text-link-green text-xl md:text-3xl font-opensans-light">We are ready to</span>
            <h5 className="text-xl md:text-2xl text-stone-800 font-opensans-extrabold">Improve YOUR Health</h5>
            <p className="text-lg md:text-xl font-opensans-light text-stone-700">
              Book an appointment to see a doctor <br /> or nurse at{" "}
              <b className="text-link-green font-opensans-extrabold">Link</b> mediclinic today!
            </p>
          </div>
          <div className="w-full flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
            <Button size="lg" href="/mediclinic/#book_appointment_wrapper">
              Book An Appointment
            </Button>
            <Button size="lg" variant="secondary" href="/mediclinic">
              View our Clinic Services
            </Button>
          </div>
        </ServiceCards>

        <ShopLinkPharmacy />

        <div className="w-full py-3">
          <div className="flex items-center mb-3">
            <p className="w-full max-w-fit pr-2 font-opensans-light text-xl md:text-2xl">Shop Main Categories</p>
            <hr className="border-t border-gray-600 w-[40%] md:w-full" />
          </div>
          <Carousel
            responsive={carouselResponsiveness}
            infinite={false}
            autoPlay={false}
            showDots={false}
            swipeable={true}
            itemClass="pr-1 md:pr-3 py-1"
          >
            {categoryCards.map((category, index) => (
              <Link key={index} to={category.url || "/#"}>
                <div className="flex flex-col items-center justify-center w-full h-[210px] p-1 rounded-lg border hover:border-none hover:shadow-md transition-all duration-300 ease-out">
                  <img
                    src={category.img}
                    alt={category.imgAlt}
                    className="flex items-center justify-center w-fit h-36 mb-1"
                  />
                  {category.desc && (
                    <p className="flex items-center justify-center w-full text-gray-700 font-opensans-bold text-sm">
                      {category.desc}
                    </p>
                  )}
                </div>
              </Link>
            ))}
          </Carousel>
        </div>

        <div className="flex flex-col w-full font-opensans mb-10">
          <div className="flex items-center mb-4">
            <p className="w-full max-w-fit pr-2 font-opensans-light text-xl md:text-2xl">
              Are you covered by health insurance? We accept
            </p>
            <hr className="border-t border-gray-600 md:w-full" />
          </div>
          <Carousel
            responsive={carouselResponsiveness}
            infinite={false}
            autoPlay={false}
            showDots={false}
            swipeable={true}
            itemClass="pr-1 md:pr-3 py-1"
          >
            {insuranceCards.map((insurance, index) => (
              <a key={index} href={insurance.url || "/#"} target="_blank" rel="noreferrer">
                <div className="flex flex-col items-center justify-center w-full rounded-lg h-56 p-3 border-stone-200 border-1">
                  <img src={insurance.img} alt={insurance.imgAlt} className="object-contain w-full h-full" />
                </div>
              </a>
            ))}
          </Carousel>
        </div>

        <div className="w-full md:h-56 lg:h-[356px] flex flex-col md:flex-row md:justify-around space-y-3 md:space-y-0 md:space-x-5 xl:space-x-10">
          <div className="w-full p-3 md:w-1/2 flex flex-col justify-between overflow-hidden bg-imgs-link-apps bg-right bg-[length:100%_100%] md:bg-cover bg-no-repeat md:h-full rounded-xl">
            <h4 className="text-base md:text-2xl font-opensans-semibold text-link-dark-gray w-2/5 lg:w-2/5 mb-4 md:mb-5">
              Get our <b className="text-link-green font-opensans-extrabold">FREE</b> Link pharmacy App today...
            </h4>
            <div className="flex flex-col space-y-1 md:space-y-3 w-full">
              <a href="https://play.google.com/store/apps/details?id=co.zm.link.linkpharmacy">
                <img src={googlePlayImage} alt="Download on Play Store" className="w-[100px] md:w-40 object-contain" />
              </a>
              <a href="https://apps.apple.com/us/app/link-health-care/id1659449779?itsct=apps_box_badge&amp;itscg=30200">
                <img src={iosImage} alt="Download on App Store" className="w-[100px] md:w-40 object-contain" />
              </a>
            </div>
          </div>

          <div className="w-full p-3 md:w-1/2 flex flex-col justify-between overflow-hidden bg-imgs-nearest-link bg-right bg-cover bg-no-repeat md:h-full rounded-xl">
            <img src={linkpharmacyLogo} alt="Link Pharmacy Logo" className="w-48 lg:w-56 object-contain" />
            <h4 className="text-sm lg:text-xl font-opensans-bold text-gray-800 md:py-3 lg:py-1 w-[50%] lg:w-[40%]">
              Find a Link pharmacy near you...
            </h4>
            <p className="text-sm md:text-base font-opensans-light text-link-dark-gray w-[40%] mb-2">
              The easiest way to save, shop and stay well...
            </p>
            <Link
              to="/find-a-mediclinic"
              className="rounded text-sm font-opensans-semibold inline-block bg-link-green text-white py-2 px-3"
            >
              Search
            </Link>
          </div>
        </div>

        <div className="w-full flex justify-center items-center -mb-16">
          <div className="text-center w-full">
            <div className="flex my-3">
              <span className="text-base md:text-xl flex items-center text-link-dark-gray pr-3 text-center ml-[17%] font-opensans-medium">
                Send me:
              </span>
              <span className="text-base md:text-xl font-opensans-medium text-gray-600">
                <Typewriter
                  options={{
                    strings: ["Health information", "Great deals", "The latest products", "Friendly advice"],
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 10,
                  }}
                />
              </span>
            </div>
            <form className="w-full flex items-end my-3">
              <input
                type="email"
                id="email"
                name="email"
                required
                placeholder="Enter your email address"
                className="h-10 bg-gray-50 rounded-tl rounded-bl w-3/5 border-0 py-1.5 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-link-green sm:text-sm sm:leading-6"
              />
              <input
                type="submit"
                value="Subscribe"
                className="h-10 bg-link-green text-white text-center sm:text-sm font-opensans-medium rounded-r py-2 px-3"
              />
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
