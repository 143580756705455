import { classNames } from "../utils/utils";

const ServiceCards = ({
  children,
  mobileBgImg,
  bgImg,
  bgImgProps,
  bgColor,
}) => {
  return (
    <section
      className={classNames(
        "rounded-md bg-stone-100 w-full h-auto md:h-80 space-y-1 p-[10px] md:p-[30px] mb-10 flex flex-col justify-between",
        mobileBgImg && `${mobileBgImg}`,
        bgImg && `${bgImg}`,
        bgImgProps ? bgImgProps : "bg-right bg-cover bg-no-repeat",
        "bg-gradient-to-b from-stone-100 to-stone-200"
      )}
    >
      {children}
    </section>
  );
};

export default ServiceCards;
